import React from 'react';
import { Link } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import UserHeader from "./userHeader";


/**
 * Render field to enter otp.
 * <p>Captures the otp enterer during login and sends it to server for verification.</p>
 * @Author : Seema Kumari
 * @version :1.0
 */


class Otp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      forgotPin: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  };

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });

  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    if (!fields["otp"]) {
      formIsValid = false;
      errors['verifyotp'] = '*Please enter your OTP.';
    }
    if (typeof fields["otp"] !== "undefined") {
    }
    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.validateForm()) {
      const url = "https://api.montra.org/moneylinkweb/api/user/verifyotp";

      // Configure the query parameters
      const params = {
        email: localStorage.getItem("email"),
        otp: this.state.fields.otp
      };

      // Make the GET request with axios
      axios.get(url, {
        headers: { "Content-Type": "application/json;charset=utf-8" },
        params: params
      })
        .then((response) => {
          if (response.status === 200 && response.data.message === "SUCCESS") {
            this.setState({
              role: response.data.data.role,
              status: response.data.status,
              message: response.data.message,
              forgotPin: true
            });
          } else {
            this.setState({
              backerrors: response.data.errors,
              status: response.data.status,
              message: response.data.message,
              serverErr: response.data.errors[0].message
            });
            response.data.errors.forEach((error) => {
              this.setState({ errorMsg: error.message });
            });
          }
        })
        .catch((error) => {
          this.setState({
            serverErr: "Please try After Sometime"
          });
          console.error("Error accessing the API:", error);
        });
    };
  };





  render() {
    if (this.state.forgotPin == true) {
      return (
        <Navigate to="/MoneyLink/UserPinSetup" />
      )
    } else {
      return (
        <div>
          <UserHeader />

          <div className="forgotpinchange">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4">
                </div>
                <div className="col-md-4">
                  <h5 className="headerpass"> Reset Your Pin </h5>
                  <form>
                    <div className="form-group">
                      <label htmlFor="password" className='pinotp'>Please enter the Otp that is send on this Email
                        <b>  : {localStorage.getItem("email")}</b>
                      </label>
                      <input type="number" className="form-control userotpfield" name="otp" placeholder="Enter Code" onChange={this.handleChange.bind(this)} />
                      <div className="errorMsgpin">{this.state.errors.verifyotp}</div>
                      <div className="errorMsgpin">{this.state.serverErr}</div>

                    </div>

                    <br />

                    <div className="sign">
                      <input type="submit" className="btn btn-warning partnerbutton" value="Submit" onClick={this.handleSubmit.bind(this)} />
                    </div>


                  </form>
                </div>

                <div className="col-md-4">
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}




export default Otp;
