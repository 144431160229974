import React, { useState, useEffect } from "react";

import personalimg1 from "../../../assets/images/personalimg1.svg";
import personalimg2 from "../../../assets/images/personalimg2.svg";
import { NavLink } from "react-router-dom";
import P2S from "../../../assets/images/P2S.svg";
import CompareLoanOffers from "../../../assets/images/CompareLoanOffers.svg";
import settlement from "../../../assets/images/settlement.svg";
import viewpolicy from "../../../assets/images/viewpolicy.svg";
import Renewinsurancepolicy from "../../../assets/images/Renewinsurancepolicy.svg";
import Lending from "../../../assets/images/lending1.svg";
import loanmanagement from "../../../assets/images/uday/Loan management system gray.svg";
import CompareInvest from "../../../assets/images/uday/Laon Repayment -gray.svg";
import powerfuldashboard from "../../../assets/images/powerfuldashboard.svg";
import Transactionhistory from "../../../assets/images/Transactionhistory.svg";
import anym from "../../../assets/images/anym.svg";
import PaymentConfirmation from "../../../assets/images/payment-confirmation.svg";
import DebitedFromMontraIcon from "../../../assets/images/Debited from montra icon.svg";
import SP from "../../../assets/images/Group 60635.svg";
import BT from "../../../assets/images/Group 89.svg";
import Pricing from "../../../assets/images/pricing.svg";
import LC from "../../../assets/images/Link cards.svg";
import LB from "../../../assets/images/Change-the-collection-Bank-account-linked.svg";
import P2P from "../../../assets/images/P2P.svg";
import RG from "../../../assets/images/refer & get.svg";
import "./style.css";
import {
  Animation1,
  Animation2,
  Animation3,
  Animation4,
  Animation4a,
  Animation5,
  Animation6,
} from "./_animations";
import personalSection1Gif from "../../../assets/images/uday/gifs/personal-section1.gif";
import personalSection2Gif from "../../../assets/images/uday/gifs/personal-section2.gif";
import PaymentProcessContainer from "../../Personal/Payment/PaymentProcessContainer";
import CompareLoanProcessContainer from "./CompareLoanProcessContainer";

const Main = () => {
  const [anim, setAnim] = useState(1);
  const [anim2, setAnim2] = useState(1);
  const [isAnimationPaused, setIsAnimationPaused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = windowWidth < 1024;

  const handleAnimMouseEnter = () => {
    setIsAnimationPaused(true);
    console.log("Personal animation is paused");
  };

  const handleAnimMouseLeave = () => {
    setIsAnimationPaused(false);
    console.log("Personal animation is started");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim((prev) => {
          return prev === 3 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isAnimationPaused) {
        setAnim2((prev) => {
          return prev === 4 ? 1 : prev + 1;
        });
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isAnimationPaused]);

  return (
    <>
      <div className="home-personal">
        <div className=" home-container personal-margin-bottom">
          <div className="row">
            <div className="col col-lg-5 home-personal-col duo-col">
              <div className="home-personal-left">
                <div className="left-1 home-left-1">
                  <h6 className="montr-sub-text">Montra - Personal</h6>
                  <h1>
                    Secure way of accessing <span>World of Payments</span>
                  </h1>
                  <p>
                    Secured with 2FA through your Montra Soft Token, you can now
                    pay using variety of Payment methods using Montra Accounts,
                    Bank Accounts & Cards you have linked on Montra App and
                    Installments by borrowing loans from our Lending Partners.
                  </p>
                  <a href="/Products/overview">
                    <button>
                      Explore Payments <i class="bi bi-chevron-right"></i>
                    </button>
                  </a>
                  <div>
                    {/* <img className="direction-img" src={personalimg1} alt="" /> */}
                    <PaymentProcessContainer />
                  </div>
                  <p>
                    Simply scan the QR codes of our Merchants or Tap & Pay on
                    our Merchant’s app with your Debit & Credit Cards or use
                    your Debit & Credit cards on POS issued by Montra to our
                    merchants.
                  </p>
                </div>
              </div>
            </div>
            <div className="col home-personal-col paymentsAnimations duo-col">
              <div className="yu-flex yu-relative">
                {!isMobile ? (
                  <React.Fragment className="normal-layout">
                    <div className={`yu-mr`}>
                      <div className="ske">
                        <img src={P2S} alt="P2S" />
                        <p className="skep">Montra Customers</p>
                      </div>
                    </div>
                    <div className={`yu-mr`}>
                      <div className="ske">
                        <img src={anym} alt="anym" />
                        <p className="skep">Mobile or Contact</p>
                      </div>
                      <div className="ske">
                        <img src={SP} alt="SP" />
                        <p className="skep">Scan & Pay</p>
                      </div>
                      <div className="ske">
                        <img src={BT} alt="BT" />
                        <p className="skep">Bank Transfer</p>
                      </div>
                    </div>
                    <div className={`yu-mr`}>
                      <div className="ske ml5">
                        <img src={DebitedFromMontraIcon} alt="Montra Account" />
                        <p className="skep">Montra Account</p>
                      </div>
                      <div className={anim === 3 ? "ske ml5 mt19" : "ske ml5"}>
                        <img src={Pricing} alt="Installments" />
                        <p className="skep">Installments</p>
                      </div>
                      <div className="ske ml5 mt30">
                        <img src={LC} alt="Linked Cards" />
                        <p className="skep">Linked Cards</p>
                      </div>
                      <div className="ske ml5">
                        <img src={LB} alt="Linked Bank" />
                        <p className="skep"> Linked Bank</p>
                      </div>
                    </div>
                    <div className={`yu-mr`}>
                      <div className="ske ml-5">
                        <img
                          src={PaymentConfirmation}
                          alt="Payout Authorization"
                        />
                        <p className="payout-skep">Payout Authorization</p>
                      </div>
                    </div>
                    <div className={`yu-mr`}>
                      <div className="ske yu-mt">
                        <img src={P2S} alt="Montra Customers" />
                        <p className="skep">Montra Customers</p>
                      </div>
                      <div className="ske yu-mt">
                        <img src={P2P} alt="P2P" />
                        <p className="skep">Montra Merchants</p>
                      </div>
                      <div className="ske yu-mt ske-paddingTop">
                        <img src={RG} alt="Other Customers" />
                        <p className="skep">Other Customers</p>
                      </div>
                    </div>
                    <div>
                      {anim === 1 && (
                        <Animation1
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                      {anim === 2 && (
                        <Animation2
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                      {anim === 3 && (
                        <Animation3
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <div className="gif-layout">
                      <img
                        src={personalSection1Gif}
                        alt="personal-section gif"
                        className="personal-section1-gif"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="row compareloanModule">
            <div className="col  duo-col">
              <div className="home-personal-left">
                <div className="left-2 left">
                  <h1 className="home-personal2-left-h1">
                    <span className="home-personal2-left-h1-span">
                      Compare loan offers
                    </span>{" "}
                    from our lending partners{" "}
                    <span className="andfont">& </span>
                    get{" "}
                    <span className="home-personal2-left-h1-span">
                      instant disbursal in your account
                    </span>
                  </h1>
                  <p>
                    Explore and compare diverse loan offers from our trusted
                    Lending Partners, giving you a range of options tailored to
                    your needs. Once you've found the right fit, enjoy the
                    convenience of instant disbursement, ensuring funds are
                    swiftly deposited into your bank account.
                  </p>
                  <a href="/Products/Finance">
                    <button>
                      Explore Lending <i class="bi bi-chevron-right"></i>
                    </button>
                  </a>
                  <div>
                    {/* <img className="direction-img" src={personalimg2} alt="" /> */}
                    <CompareLoanProcessContainer />
                  </div>
                </div>
              </div>
            </div>
            <div className="col  lendingAnimations duo-col">
              <div className="yu-flex yu-relative">
                {!isMobile ? (
                  <React.Fragment className="normal-layout">
                    <div className="yu-mr-2">
                      <div
                        className={
                          anim2 === 4
                            ? `ske animation6-montracustomer-top`
                            : anim2 === 3
                              ? `ske animation5-montra-customer`
                              : `ske montracustomer`
                        }
                      >
                        <img src={P2S} alt="P2S" />
                        <p className="skep">Montra Customers</p>
                      </div>
                    </div>
                    <div className="yu-mr-2">
                      <div className="ske mt-30">
                        <img src={CompareLoanOffers} alt="CompareLoanOffers" />
                        <p className="skep">Loan Eligibility Comparison</p>
                      </div>
                      <div
                        className={
                          anim2 === 3
                            ? // ? `ske animation5-loanstatement`
                            "ske mt130"
                            : anim2 === 4
                              ? `ske animation6-ske-loanstatement`
                              : `ske mt130 `
                        }
                      >
                        <img src={Transactionhistory} alt="Transactionhistory" />
                        <p className="skep">Loan Statement</p>
                      </div>
                    </div>
                    <div className="yu-mr-2">
                      <div className="ske mt-30">
                        <img src={viewpolicy} alt="viewpolicy" />
                        <p className="skep">Loan Offer Acceptance</p>
                      </div>
                      <div
                        className={
                          anim2 === 4
                            ? ` ske animation6-ske-lendingpartner`
                            : // : `ske mt50 ml-30`
                            `ske mt60`
                        }
                      >
                        <img src={settlement} alt="settlement" />
                        <p className="skep">Lending Partners</p>
                      </div>
                      <div
                        className={
                          anim2 === 4
                            ? `ske animation6-ske-loanrepayment`
                            : anim2 === 3
                              ? `ske animation5-loanrepayment`
                              : `ske mt50`
                        }
                      >
                        <img src={CompareInvest} alt="CompareInvest" />
                        <p className="loan-repayment ">Loan Repayment</p>
                      </div>
                      {/* <div
                        className={
                          anim2 === 2
                            ? ` ske animation5-ske-loanservice`
                            : `ske mt43`
                        }
                      >
                        <img src={powerfuldashboard} alt="" />
                        <p>Loan Mgt. Service</p>
                      </div> */}
                    </div>

                    <div className="yu-mr-2">
                      <div className="ske mt-30">
                        <img src={Renewinsurancepolicy} alt="Renewinsurancepolicy" />
                        <p className="skep">Loan Authorization</p>
                      </div>
                    </div>

                    <div className="yu-mr-2">
                      <div className="ske mt-30">
                        <img src={Lending} alt="Lending" />
                        <p className="skep">Instant Loan Disbursement</p>
                      </div>

                      <div
                        className={
                          anim2 === 4
                            ? ` ske animation6-ske-loanmanagement`
                            : `ske loanmanagement`
                        }
                      >
                        <img src={loanmanagement} alt="loanmanagement" />
                        <p className="skep">Loan Mgt. System</p>
                      </div>
                    </div>
                    <div>
                      {/* {anim2 === 1 && <Animation4 />}
                  {anim2 === 2 && <Animation5 />}
                  {anim2 === 3 && <Animation6 />} */}
                      {anim2 === 1 && (
                        <Animation4
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                      {anim2 === 2 && (
                        <Animation4a
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                      {anim2 === 3 && (
                        <Animation5
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}

                      {anim2 === 4 && (
                        <Animation6
                          onMouseEnter={handleAnimMouseEnter}
                          onMouseLeave={handleAnimMouseLeave}
                        />
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <>
                    <div className="gif-layout">
                      <img
                        src={personalSection2Gif}
                        alt="personal-section gif"
                        className="personal-section1-gif"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
